<template>
  <v-card elevation="6" style="min-height:100%" class="my-1">
    <v-card-title class="text-uppercase  d-flex justify-center">{{
      $tc("invoices.tax", 2)
    }}</v-card-title>
    <v-simple-table id="items">
      <tbody>
        <tr>
          <th class="font-weight-light text-uppercase">
            {{ $tc("expenses.concepto", 1) }}
          </th>
          <th class="font-weight-light text-uppercase">
            Base
          </th>
          <th class="font-weight-light text-uppercase">
            %
          </th>
          <th class="font-weight-light text-uppercase">
            Cuota
          </th>
          <th class="font-weight-light text-uppercase"></th>
        </tr>

        <tr v-for="(tax, i) in formateTaxes" :key="i">
          <td style="font-size: 16px !important">
            {{ tax.name }}
            <span v-if="tax.included">(incl.)</span>
          </td>
          <td style="font-size: 16px !important">{{ $n(base, "currency") }}</td>
          <td style="font-size: 16px !important">
            {{ $n(tax.percentage / 100, "percent") }}
          </td>
          <td style="font-size: 16px !important">
            {{ $n(taxFee(tax), "currency") }}
          </td>
          <td>
            <v-btn icon @click="delItem(i)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5">
            <v-dialog width="300" v-model="dialog">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="addTax = null">
                  <v-icon size="36">mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Añadir Impuesto</v-card-title>
                <div class="close">
                  <v-btn icon @click="dialog = false" small>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-text class="py-0">
                  <v-row justify="center" align="center">
                    <v-col>
                      <v-select
                        outlined
                        dense
                        :items="pendingTaxes"
                        :item-text="
                          (x) => `${x.name} ${x.included ? '(incl.)' : ''}`
                        "
                        return-object
                        v-model="addTax"
                        hide-details
                      ></v-select>
                    </v-col>
                    <AddTax @update="addNewTax" />
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn height="30px" elevation="0" @click="add">
                    <v-icon size="14px" class="mr-1">$save</v-icon>
                    Añadir</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </td>
        </tr>
      </tfoot>
    </v-simple-table>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "InvoiceTaxes",
  props: {
    invoice: {
      type: Object,
      default: () => ({ items: [], taxes: [] }),
    },
    base: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      items: [],
      addTax: null,
      dialog: false,
    };
  },
  computed: {
    formateTaxes() {
      return this.invoice.taxes;
    },
    pendingTaxes() {
      return this.items.filter((i) => {
        let t = this.invoice.taxes.find((t) => t.id == i.id);
        if (t) return false;
        return true;
      });
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("taxes", ["getTaxes"]),
    fetch() {
      this.getTaxes({}).then((response) => {
        this.items = response.data;
        this.invoice.taxes = response.data.filter((t) => t.default == true);
      });
    },
    taxFee(tax) {
      return (this.base * tax.percentage) / 100;
    },
    delItem(i) {
      this.invoice.taxes.splice(i, 1);
    },
    add() {
      this.invoice.taxes.push(this.addTax);
      this.addTax = null;
      this.dialog = false;
    },
    addNewTax(tax) {
      this.addTax = tax;
      this.fetch();
      this.add();
    },
  },
  components: {
    AddTax: () => import("./taxes/New"),
  },
};
</script>
<style lang="sass" scoped>
#items
  th
    color: var(--v-primary-base) !important
    text-transform: uppercase
    font-size: 16px
</style>
